import React from "react";
import { TransformWrapper, TransformComponent } from "@pronestor/react-zoom-pan-pinch";
import "./styles/Map.css";


const Map = (props) => {
    const bucket = process.env.REACT_APP_AWS_BUCKET_NAME_;
    const imageURL = "https://" + bucket + ".s3.amazonaws.com/" + props.num + "/map.jpg";

    return (
        <TransformWrapper wheel={{ step: (0.1) }} maxScale={6 * (1200 / props.width)} className="map-wrapper">
            <TransformComponent className="map-container">
                <div className="map-outer">
                    <img
                        id={props.loaded ? (props.long ? "map-w" : "map-h") : "map-hidden"}
                        alt="map"
                        src={imageURL}
                        onLoad={props.load}
                        width="auto"
                    />
                </div>
            </TransformComponent>
        </TransformWrapper>
    );
};

export default Map;
